/*
 *
 */

import React from 'react';
import Section from 'components/section';
import Text from 'components/text';
import Hero from 'components/hero';
import H3 from 'components/h3';
import { useIntl } from 'react-intl';
import Helmet from 'react-helmet';


/*
 *
 */

export default function MentionsLegales () {

	const intl = useIntl();

	return (
		<React.Fragment>
			<Helmet>
				<title>{intl.formatMessage({ id: 'page.legal.meta_title' })}</title>
				<meta name="description" content={intl.formatMessage({ id: 'page.legal.meta_desc' })} />
			</Helmet>
			<Section>
				<Hero
					title={intl.formatMessage({ id: 'page.legal.section.hero.title' })}
				/>
			</Section>
			<Section>
				<Text>
					<H3>Article 1 : Propriété et modification du Site Internet</H3>
					<p>Le site Internet www.aide-aux-restaurateurs.fr (ci-après : le « Site Internet ») et ses sous-domaines est administré par et est la propriété de Centralapp SA, dont le siège social est établi à 1000 Brussels, Rue d’Alost, 7, inscrite à la Banque Carrefour des Entreprises sous le numéro 0545.941.437 (ci-après “CENTRALAPP”).</p>
					<p>Pour toute question, réclamation et/ou remarque concernant le Site Internet, vous pouvez nous contacter par e-mail à l'adresse suivante : legal@centralapp.com</p>
					<p>CENTRALAPP se réserve le droit de modifier à tout moment et sans avertissement préalable les présentes Conditions générales d'utilisation (ci-après « CGU »), en vue de se conformer aux obligations légales ou d'améliorer son service. Toute version adaptée des présentes CGU entre en vigueur à compter de la première date de publication sur le Site Internet. C'est pourquoi nous vous conseillons de consulter le Site Internet régulièrement.</p>
					<p>L'utilisation du Site Internet implique que l'« Utilisateur » du Site Internet (comprenant, sans être exhaustif, le visiteur pur du Site Internet, le client, etc.) accepte de manière intégrale et irrévocable les conditions et directives d'emploi du Site Internet reprises dans les présentes CGU, ainsi que tout autre document légal mentionné sur le Site Internet.</p>
					<H3>Article 2 : Droits de propriété intellectuelle</H3>
					<p>Tous les éléments du Site Internet (en ce compris, sans être exhaustif, les logiciels utilisés, le code source, l’agencement, le texte, les logos, les photographies, les dessins, les images, les sons, les bases de données, les noms, ainsi que les noms commerciaux et de domaine) sont des œuvres protégées par des droits d'auteur et/ou d'autres droits de propriété intellectuelle, y compris le droit des marques.</p>
					<p>Tous les droits de propriété en question, y compris les droits de propriété intellectuelle (droits d'auteur, droits de marque, droits sur les bases de données, droits sur les croquis et modèles, etc.) appartiennent à CENTRALAPP ou sont intégrés au Site Internet avec l'accord du propriétaire des droits en question.</p>
					<p>Aucune partie du Site Internet lui-même, ni les données et informations présentées sur le Site Internet, ne peuvent être enregistrées (autres que celles nécessaires pour pouvoir visiter le Site Internet) ou reproduites, modifiées, traduites, rendues publiques, distribuées, louées ou vendues, transmises à des tiers ou utilisées de quelque manière que ce soit sans l'autorisation écrite préalable de CENTRALAPP.</p>
					<H3>Article 3 : Responsabilité</H3>
					<p>CENTRALAPP veille à communiquer des informations aussi correctes que possible mais ne peut jamais garantir l'entière exactitude, la complétude et la pertinence des informations présentées sur le Site Internet et ne peut par conséquent en être tenue pour responsable. Cela s'applique aux informations postées par CENTRALAPP elle-même sur le Site Internet, mais aussi aux informations provenant d'Utilisateurs ou autres tierces parties. « Information » signifie tout ce qui peut être trouvé sur le Site Internet, y compris texte, images, sons, données, etc.</p>
					<p>CENTRALAPP décline toute responsabilité quant aux décisions qui seraient prises ou actes qui seraient entrepris par l'Utilisateur sur la base de l’Information fournie par le Site Internet, et ne peut être tenue pour responsable des erreurs causées par l'Utilisateur sur la base d’informations fournies par le Site Internet.</p>
					<p>CENTRALAPP décline également toute responsabilité pour les préjudices directs ou indirects qui pourraient résulter de l'inexactitude, de l'absence de complétude, du manque de pertinence, de l'omission ou de la négligence dans la production, l'élaboration, la rédaction, l'écriture et l'interprétation des informations du Site Internet.</p>
					<p>CENTRALAPP décline toute responsabilité pour les préjudices ou défauts, permanents ou temporaires, des équipements informatiques ou données de l’Utilisateur pendant ou après l’utilisation du Site Internet. En particulier, CENTRALAPP décline toute responsabilité pour la possible transmission de virus et chevaux de Troie via son Site Internet.</p>
					<p>CENTRALAPP décline toute responsabilité quant aux liens vers des sites Internet gérés par des tiers, ou pour tout préjudice causé par la visite de tels sites Internet. CENTRALAPP ne peut exercer aucun contrôle sur ces sites Internet et ne peut être tenue responsable de leur contenu.</p>
					<p>En ce qui concerne la réalisation du Site Internet, CENTRALAPP fait appel autant que possible aux technologies les plus modernes. CENTRALAPP ne peut toutefois pas être tenue responsable pour les pannes (temporaires) ou défaillances ou travaux de maintenance éventuels sur le - ou du - Site Internet.</p>
					<H3>Article 4 : Confidentialité</H3>
					<p>Étant donné l'évolution de la législation en matière de confidentialité, CENTRALAPP se réserve le droit de modifier à tout moment sa Politique de Confidentialité. Par conséquent, CENTRALAPP conseille à l'Utilisateur de vérifier régulièrement sur le Site Internet si des modifications sont intervenues dans la Politique de Confidentialité.</p>
					<H3>Article 5 : Non-renonciation</H3>
					<p>Le fait pour CENTRALAPP de ne pas mettre en œuvre toute provision de ces CGU, ou d’entreprendre toute action à l’égard d’un Utilisateur du Site Internet en cas d’éventuelle violation de l’une des dispositions, ne peut être interprété comme une renonciation à une créance ou un droit lié à une quelconque disposition dans le contexte d'une éventuelle violation future par un Utilisateur.</p>
					<H3>Article 6 : Divisibilité</H3>
					<p>Si une quelconque disposition des présentes CGU est déclarée en tout ou en partie illégale, nulle ou non-exécutoire en vertu du droit applicable, cette disposition ne devra plus faire partie des présentes CGU. La légalité, la validité et le caractère contractuel des autres dispositions des présentes CGU restent inaltérés.</p>
					<H3>Article 7 : Tribunal compétent et droit applicable</H3>
					<p>Les présentes CGU ont été établies, doivent être interprétées et sont régies par et conformément au droit belge. Les cours et tribunaux compétents pour connaître des contestations relatives aux présentes CGU sont ceux de Bruxelles, chambres francophones, sauf disposition légale contraignante contraire.</p>
				</Text>
			</Section>
		</React.Fragment>
	);
}