//
import React from "react"

//
import Layout from '../layout/index';
import MentionsLegales from 'routes/mentions-legales/index.js';

/*
 *
 */
export default (props) => (
	<Layout {...props}>
		<MentionsLegales />
	</Layout>
);